import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Web3 from "web3";
// import { ourABI } from "../../ABI/gingerBreadBoys";
import { useWeb3React } from "@web3-react/core";
import gif2 from "../../images/giff.gif";
import bg from "../../images/MintImage.png";
// import heroBackground from "../../images/bgs/MintDapp.png";
// import heroBackgroundMobile from "../../images/bgs/MintDapp.png";
import { ethers } from "ethers";
import {TwitterShareButton } from 'react-twitter-embed';

import heroBackground from '../../images/bgs/Home1.png'
import heroBackgroundMobile from '../../images/bgs/Home1.png'

import dapp from '../../images/bgs/MintDapp2.png'

import downarrow from "../../images/downarrow.png";
import WalletConnect from "../WalletConnect/WalletConnect";

import { Container, Row, Col } from "react-bootstrap";
import { FiPlus, FiMinus } from "react-icons/fi";
import { ourABI, contractAddress, whitelistAddresses, freeMintAddresses } from "../../ABI/gingerBreadBoys";
import WalletButton from "../WalletConnect/WalletConnect"
import MerkleTree from "merkletreejs";
import keccak256 from "keccak256";
import sha1 from "crypto-js/sha1";

const Wrapper = styled.section`
  // background: url(${bg}) no-repeat center center;
  // background-size: cover;
  // padding: 11rem 0;
  text-align: center;
  padding-top: 120px;

  .wallet_button {
    color: #fff;
    background-color: #000;
    padding: 1.5rem;
    font-size: 2.5rem;
    font-family: "AmericanPurpose";
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.2s all;
    // margin-bottom: 2rem;
  
    &:hover {
      color: #fff;
      background-color: #2c2c2c
      // border: 1px solid #000;
    }
  }

  @media all and (max-width: 1023px) {
    margin-bottom: -10px;
    margin-top: -10px;
    // padding: 5rem;
  }
  .main {
    max-width: 750px;
    margin-left: 40%;
    // height: 80vh;
    @media all and (max-width: 1512px) {
        // height: initial;
      max-width: 100%;
      margin-left: 0;
    }
  }
`;

const Background = styled.div`
  background: url(${heroBackground}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;


  @media all and (max-width: 767px) {
    background-image: url(${heroBackgroundMobile});
    background-size: cover;
    padding-top: initial;
  }
`;

const Box = styled.div`
  // background-color: white;
  background-image: url(${dapp});
  padding: 30px;
  max-width: 500px;
  border: 10px solid black;
  margin: 0 auto;
  p {
    margin: 10px 0;
  }
`;

const BoxContainer = styled.div`
  color: #fff;
  padding: 20px 25px 40px;
  @media all and (max-width: 767px) {
    padding: 20px 10px;
  }
`;

const BoxContainer2 = styled.div`
  color: #fff;
  padding: 20px 25px;
  position: fixed;
  @media all and (max-width: 1512px) {
    position: relative;
    margin: 0 auto;
    .mobile-fix {
      display: flex;
      flex-direction: row;
    }
    .mobile-left, .mobile-right {
      padding: 0 15px;
    }
    .d-only {
      display: none;
    }
  }
  @media all and (max-width: 660px) {
    .mobile-fix {
      display: flex;
      flex-direction: column;
    }
    .mobile-left, .mobile-right {
      padding: 0;
    }
    .d-only {
      display: none;
    }
  }
  @media all and (max-width: 1300px) and (max-height: 950px) {
  }
  @media all and (min-width: 1300px) and (max-height: 950px) {
    position: relative;
    margin: 0 auto;
    .mobile-fix {
      display: flex;
      flex-direction: row;
    }
    .mobile-left, .mobile-right {
      padding: 0 15px;
    }
    .d-only {
      display: none;
    }
  }
`;


const BoxContainer3 = styled.div`
  color: #fff;
  padding: 20px 25px;
  // position: fixed;
  // top: 480px;
  div {
    max-width: 400px;
  }
  @media all and (max-width: 1300px) {
    position: relative;
    margin: 0 auto;
    top: auto;
    .mobile-fix {
      display: flex;
      flex-direction: row;
    }
    .mobile-left, .mobile-right {
      padding: 0 15px;
    }
    .d-only {
      display: none;
    }
  }
  @media all and (max-width: 660px) {
    .mobile-fix {
      display: flex;
      flex-direction: column;
    }
    .mobile-left, .mobile-right {
      padding: 0;
    }
    .d-only {
      display: none;
    }
  }
  @media all and (max-width: 1300px) and (max-height: 950px) {
  }
  @media all and (min-width: 1300px) and (max-height: 950px) {
    position: relative;
    margin: 0 auto;
    .mobile-fix {
      display: flex;
      flex-direction: row;
    }
    .mobile-left, .mobile-right {
      padding: 0 15px;
    }
    .d-only {
      display: none;
    }
  }
`;

const Image = styled.img`
  border: 5px solid #000;
`;

const H2 = styled.h2`
  color: #fff;
  padding-top: 50px;
`;

const Button = styled.button`
  color: #fff;
  background-color: #000;
  padding: 1.5rem;
  margin: 0 5px;
  font-size: 2.5rem;
  font-family: "AmericanPurpose";
  border: 1px solid transparent;
  cursor: pointer;
  transition: 0.2s all;
  margin-bottom: 2rem;

  &:hover {
    color: #fff;
    background-color: #2c2c2c;
    // border: 1px solid #000;
  }
`;

const Select = styled.select`
  background: url(/static/media/downarrow.9edfd30d.png);
  background-repeat: no-repeat;
  background-position: 84% center;
  background-color: #000;
  background-size: 20px;
  appearance: none;
  outline: none;
  padding: 1.5rem;
  margin-right: 1rem;
  padding-right: 3.4rem;
  font-size: 2.5rem;
  font-family: "AmericanPurpose";
  cursor: pointer;
  color: #fff;
`;

const Loader = styled.div`
  border-radius: 50%;
  width: 5em;
  height: 5em;
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #818181;
  border-right: 1.1em solid #818181;
  border-bottom: 1.1em solid #818181;
  border-left: 1.1em solid black;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  overflow: hidden;
  
  &::after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const FreeMint = () => {
  const [num, setNum] = useState("");
  const [rewardSuccessfulPurchase, setRewardSuccessfulPurchase] =
    useState(false);
  const [accountConnected, setAccountConnected] = useState(false);
  const [rewardHasError, setRewardHasError] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const [rewardTransactionHash, setRewardTransactionHash] = useState("");
  const [amount, setAmount] = useState("200000000000000000");
  const [price, setPrice] = useState("0.2");
  const [share, setShare] = useState("");
  const { account, library } = useWeb3React();
  const optionsArray = [...Array(25).keys()];

  const [freeMintSnapshot, setFreeMintSnapshot] = useState("");
  const [whitelistEthPrice, setWhitelistEthPrice] = useState("");
  const [publicTopiaPrice, setPublicTopiaPrice] = useState("");
  const [publicEthPrice, setPublicEthPrice] = useState("");

  const [whitelistClaimedAmount, setWhitelistClaimedAmount] = useState('');
  const [whitelistMaxPerWallet, setwhitelistMaxPerWallet] = useState('');
  const [maxSupply, setMaxSupply] = useState('');
  const [connectedAccount, setConnectedAccount] = useState(false);

  const [hasErrorApeWhitelist, setHasErrorApeWhitelist] = useState("");
  const [hasErrorTopiaWhitelist, setHasErrorTopiaWhitelist] = useState("");
  const [hasErrorEthWhitelist, setHasErrorEthWhitelist] = useState("");

  const [isLoadingApeWhitelist, setIsLoadingApeWhitelist] = useState(false);
  const [isLoadingTopiaWhitelist, setIsLoadingTopiaWhitelist] = useState(false);
  const [isLoadingEthWhitelist, setIsLoadingEthWhitelist] = useState(false);

  const [mintsApeWhitelist, setMintsApeWhitelist] = useState(1);

  const [mintsTopiaWhitelist, setMintsTopiaWhitelist] = useState(1);

  const [maxAmountUserCanMint, setMaxAmountUserCanMint] = useState();
  // console.log('maxAmountUserCanMint',maxAmountUserCanMint)

  const [index, setIndex] = useState();
  // console.log('index',index)
  const [lower, setLower] = useState();

  const [ethOptionSelectedToMintWhitelist, setEthOptionSelectedToMintWhitelist] = useState(1);
  // console.log('ethOptionSelectedToMintWhitelist', ethOptionSelectedToMintWhitelist)

  const [topiaOptionSelectedToMintWhitelist, setTopiaOptionSelectedToMintWhitelist] = useState(1);
  // console.log('topiaOptionSelectedToMintWhitelist', topiaOptionSelectedToMintWhitelist)

  const [whitelistAlreadyMintedAmount, setWhitelistAlreadyMintedAmount] = useState();
  // console.log('whitelistAlreadyMintedAmount', whitelistAlreadyMintedAmount)

  const [publicAlreadyMintedAmount, setPublicAlreadyMintedAmount] = useState();
  // console.log('publicAlreadyMintedAmount', publicAlreadyMintedAmount)

  const [whitelistEthPriceForAmount, setWhitelistEthPriceForAmount] = useState();
  const [publicEthPriceForAmount, setPublicEthPriceForAmount] = useState();

  const [publicMaxPerWallet, setPublicMaxPerWallet] = useState();
  // console.log('publicAlreadyMintedAmount', publicAlreadyMintedAmount)

  const [publicMaxReached, setPublicMaxReached] = useState(false);
  // console.log('publicMaxReached', publicMaxReached)

  const [whitelistMaxReached, setWhitelistMaxReached] = useState(false);
  // console.log('whitelistMaxReached', whitelistMaxReached)

  const [freeIndex, setFreeIndex] = useState();


  const [successfulPurchaseApeWhitelist, setsuccessfulPurchaseApeWhitelist] = useState(false);
  const [successfulPurchaseTopiaWhitelist, setsuccessfulPurchaseTopiaWhitelist] = useState(false);
  const [successfulPurchaseEthWhitelist, setsuccessfulPurchaseEthWhitelist] = useState(false);

  const [ethBalance, setEthBalance] = useState(false);

  const [tokenBalance, setTokenBalance] = useState('');

  const topiaContractFinal = '0xcccCb68e1A848CBDB5b60a974E07aAE143ed40C3'
  // 0xcccCb68e1A848CBDB5b60a974E07aAE143ed40C3 hytopia

  useEffect(async () => {
    const web3 = new Web3(Web3.givenProvider);
    const ourContract = new web3.eth.Contract(ourABI, contractAddress);

    if (account) {

      ourContract.methods.freeSnapshot(account).call()
        .then((resp) => {
          setFreeMintSnapshot(resp)
        })

      setConnectedAccount(true)
      setLower(account.toLowerCase())
      const balanceOfABI = [
        {
          "constant": true,
          "inputs": [
            {
              "name": "_owner",
              "type": "address"
            }
          ],
          "name": "balanceOf",
          "outputs": [
            {
              "name": "balance",
              "type": "uint256"
            }
          ],
          "payable": false,
          "stateMutability": "view",
          "type": "function"
        },
      ];

      const contract = new web3.eth.Contract(balanceOfABI, topiaContractFinal)

      async function getTokenBalance() {
        // Execute balanceOf() to retrieve the token balance
        const result = await contract.methods.balanceOf(account).call(); // 29803630997051883414242659

        // Convert the value from Wei to Ether
        const formattedResult = web3.utils.fromWei(result, "ether"); // 29803630.997051883414242659

        setTokenBalance(formattedResult)
      }
      getTokenBalance();

      const getBal = await web3.eth.getBalance(account);
      var balance = web3.utils.fromWei(getBal, "ether")
      setEthBalance(Number(balance).toFixed(4))
    }

    ourContract.methods.totalSupply().call()
      .then((resp) => {
        setNum(resp);
      })
  })

  const TRUNCATE_REGEX = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

  const truncateAddress = (address) => {
    const match = address.match(TRUNCATE_REGEX);
    if (!match) return address;
    return `${match[1]}...${match[2]}`;
  };

  useEffect(async () => {
    if (account) {
      if (freeMintAddresses.includes(account)) {
        const leafNodes = freeMintAddresses.map(addr => keccak256(addr))

        const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true })

        // console.log('freemint root: ', merkleTree.toString())

        // final free mint merkle tree: 0x54b5fe9a3f57d97489e0b587ce91174f36aefe99df85a12aa8cf36e960dc46cf

        // console.log('includes',freeMintAddresses.findIndex(i => i.includes(account.toString())))
        const hexProof = merkleTree.getHexProof(leafNodes[freeMintAddresses.findIndex(i => i.includes(account.toString()))])
        // console.log('leafNodes',hexProof)
        // if (hexProof !== 0) {
        setFreeIndex(hexProof)
        // } else {
        // setFreeIndex(0)
        // }
      }
      // console.log('freeIndex', freeIndex)
    }
  }, account)

  const freeMint = async () => {
    setHasErrorEthWhitelist('')
    if (account) {
      const web3 = new Web3(library.provider);
      const ourContract = new web3.eth.Contract(ourABI, contractAddress);
      setIsLoadingEthWhitelist(true);
      ourContract.methods.freeMint(freeIndex)
        .send({
          from: account,
        })
        .on("receipt", function (receipt) {
          setIsLoadingEthWhitelist(false);
          setsuccessfulPurchaseEthWhitelist(true);
          setTransactionHash(receipt.transactionHash)
          setHasErrorEthWhitelist('')
          ourContract.methods.totalSupply().call()
            .then((resp) => {
              setNum(resp);
            })
            .catch(function (error) {
              setNum('Connect wallet to view total')
            })
        })
        .on("error", function (error) {
          setIsLoadingEthWhitelist(false)
          setHasErrorEthWhitelist(error.message) // transaction rejected
          setsuccessfulPurchaseEthWhitelist(false)
        })
        .finally(() => {
          setIsLoadingEthWhitelist(false);
        });
    } else {
    }
  }

  return (
    <Wrapper id="buy">
      <Background>
        {/* <H2>mint jack rabbits loot</H2> */}
        <div className="globalWrapper">
          {/* <h2>mint with $ape</h2> */}
          <div className="grid">

            {/* eth whitelist start */}
            <BoxContainer2>
              <Box>
                <div className="hero-footer">
                  <Row className="middeling">
                    <Col md={12} lg={12} className="mobile-fix">

                      <div className="mobile-left">
                        <p style={{ fontSize: '2rem' }}>JRL Total minted:<br />{num} - 7777</p>
                        {/* <hr className="d-only" /> */}
                      </div>

                      <div className="mobile-right">
                        {connectedAccount && freeMintAddresses.includes(account)
                          ? <>
                            <hr className="d-only" />
                            <p style={{ fontSize: '2rem', textDecoration: 'underline' }}> YOUR info</p>
                            <p>{account && truncateAddress(account)}</p>
                          </>
                          : ''
                        }
                      </div>

                    </Col>
                  </Row>
                </div>
              </Box>
            </BoxContainer2>
            <BoxContainer3>

              <Box>
                <img src={gif2} alt="bucks" />
              </Box>
            </BoxContainer3>

            {/* <BoxContainer className="col-2" /> */}

            {/* eth whitelist start */}

            <BoxContainer className="col-12">
              <Box className="main">
                <h2>free mint</h2>
                <div className="hero-footer">
                  <Row className="middeling">
                    <Col md={12} lg={6} className="">
                      <WalletButton />
                      {/* {connectedAccount && !freeMintAddresses.includes(account)
                                                ?
                                                <p>not eligible for free mint</p>
                                                :
                                                ''
                                            } */}
                      {/* {console.log('eligible for free mint',freeMintAddresses.includes(account) && freeMintSnapshot !== '1')} */}
                      {(freeMintAddresses.includes(account) && freeMintSnapshot !== '1')
                        ?
                        <>
                          <br />
                          <p>eligible for free mint</p>
                          <Col className="px-2">
                            <Button
                              className="button"
                              onClick={freeMint}
                            >free Mint</Button>
                          </Col>
                        </>
                        : <p>not eligible for free mint</p>
                      }
                      {/* {!freeMintSnapshot === '1'
                                                ??
                                                <>
                                                <br />
                                                <p>eligible for free mint</p>
                                                <Col className="px-2">
                                                    <Button
                                                        className="button"
                                                        onClick={freeMint}
                                                    >free Mint</Button>
                                                </Col>
                                            </>
                                            } */}
                      {/* {!freeMintSnapshot === ''
                                                ?
                                                <>
                                                <br />
                                                <p>eligible for free mint</p>
                                                <Col className="px-2">
                                                    <Button
                                                        className="button"
                                                        onClick={freeMint}
                                                    >free Mint</Button>
                                                </Col>
                                            </> : <p>testtt</p>
                                            } */}
                      {isLoadingEthWhitelist ?
                        <>
                          <Loader>Loot in progress...</Loader>
                          <p className="">
                            mint in progress...
                          </p>
                        </>
                        : ''
                      }

                      {hasErrorEthWhitelist ?
                        <>
                          <hr />
                          <p>{hasErrorEthWhitelist}</p>
                        </>
                        : ''
                      }

                      {successfulPurchaseEthWhitelist
                        ? <>
                          <hr />
                          <p style={{ fontSize: '30px' }}>Loot complete</p>
                          <p style={{ fontSize: '25px' }}>welcome to the posse</p>
                          <hr />
                          <p><a className="link-dark" href={`https://opensea.io/account`} target="_blank">Gimme the loot on Opensea</a></p>
                          <p><a className="link-dark" href={`https://www.etherscan.io/tx/${transactionHash}`} target="_blank">Click to view your transaction</a></p>
                          <div className="centerContent">
                  <div className="selfCenter spaceBetween">
                    <TwitterShareButton
                      onLoad={function noRefCheck() { }}
                      options={{
                        buttonHashtag: undefined,
                        screenName: undefined,
                        size: 'large',
                        text:
`I just minted @JackRabbitsLoot - highest res on OpenSea for a collection, amazing street art, printable after mint, signed copies available, and Hytopia gaming and staking utility. 

Join the posse! http://jackrabbitsloot.com

Are you in?

$topia @opensea

`,
                        // via: 'JackRabbitsLoot'
                      }}
                      // placeholder={<div style={{ backgroundColor: 'red', color: 'white', margin: 10, padding: 10 }}>Hello I am custom placeholder</div>}
                      url="discord.gg/RqfsJzDZsk"
                    />
                  </div>
                </div>
                          {/* <div className="centerContent">
                            <div className="selfCenter spaceBetween">
                              <TwitterShareButton
                                onLoad={function noRefCheck() { }}
                                options={{
                                  buttonHashtag: undefined,
                                  screenName: undefined,
                                  size: 'large',
                                  text: 'I minted @JackRabbitsLoot and you should to, join the posse https:/www.jackrabbitsloot.com/mint',
                                  via: 'JackRabbitsLoot'
                                }}
                                placeholder={<div style={{ backgroundColor: 'red', color: 'white', margin: 10, padding: 10 }}>Hello I am custom placeholder</div>}
                                url="https://facebook.com/saurabhnemade"
                              />
                            </div>
                          </div> */}
                        </>
                        : ''}

                    </Col>
                  </Row>
                </div>
              </Box>

            </BoxContainer>

          </div>
        </div>
      </Background>
    </Wrapper>
  );
};

export default FreeMint;
