import styled from "styled-components";

import heroBackground from "../../images/bgs/Laundromat.png";
import heroBackgroundMobile from "../../images/bgs/Laundromat.png";

const Wrapper = styled.section``;

const Background = styled.div`
  background-image: url(${heroBackground});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;

  @media all and (max-width: 767px) {
    background-image: url(${heroBackgroundMobile});
    background-size: cover;
    padding-top: initial;
  }
`;

const Box = styled.div`
  background-color: white;
  padding: 30px;
  p {
    margin: 0;
  }
  a {
    color: black;
  }
`;

const BoxContainer = styled.div`
  padding: 160px 25px 70px;
`;

const H2 = styled.h2`
  background: white;
  color: black;
  margin: 0 auto 40px;
  padding: 20px 0 15px;
  text-align: center;
  width: 240px;
`;

const H1 = styled.h1`
  color: #fff;
  margin: 0 auto;
  padding: 30px 0 15px;
  text-align: center;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
`;

const AboutSection = () => {
  return (
    <Wrapper id="laundromat">
      <Background>
        <div className="globalWrapper">
          <H1>COMING SOON</H1>
        </div>
      </Background>
    </Wrapper>
  );
};

export default AboutSection;
