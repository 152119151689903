import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core'
import { ConnectorId, useWalletModal } from '@pantherswap-libs/uikit'
import { injected, bsc, walletconnect } from '../../connectors'
import Web3 from "web3";

const WalletConnect = () => {
    const { account, activate, deactivate } = useWeb3React()
    const web3 = new Web3(Web3.givenProvider);

    useEffect(async () => {
        // signIn()
    }, [])

    const TRUNCATE_REGEX = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

    const truncateAddress = (address) => {
        const match = address.match(TRUNCATE_REGEX);
        if (!match) return address;
        return `${match[1]}...${match[2]}`;
    };

    async function signIn() {
        window.ethereum.enable()
            .then(function (accounts) {
                web3.eth.getChainId().then((chainId) => {
                    // console.log('chainId', chainId);
                    if (chainId != 1) { // 11155111 testnet 1 mainnet
                        alert("You are on the wrong network. Change network to Ethereum.");
                    } else {
                        let wallet = accounts[0]
                        handleLogin(wallet)
                    }
                });
            })
    }

    const handleLogin = (connectorId) => {
        if (connectorId === 'walletconnect') {
            return activate(walletconnect)
        }
        else if (connectorId === 'bsc') {
            return activate(bsc)
        }
        // console.log('injected',injected)
        return activate(injected)
    }

    const { onPresentConnectModal } = useWalletModal(handleLogin, deactivate, account)

    const { onPresentAccountModal } = useWalletModal(handleLogin, deactivate, account)

    return (
        !account ?
            <button type="button" className="wallet_button not-connected" onClick={onPresentConnectModal}>Connect Wallet</button>
            :
            <>
                <button type="button" className="wallet_button connected" onClick={onPresentAccountModal}>
                    Wallet Connected <br />
                    <span style={{ fontSize: '1.5rem' }}>{truncateAddress(account)}</span>
                </button>
            </>
    )
}

export default WalletConnect
