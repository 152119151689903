import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Home/Home";
import Navbar from "./Components/Navbar/Navbar";
import MyDollars from "./Components/MyDollars/MyDollars";
import Laundromat from "./Components/Laundromat/Laundromat";
import "./App.scss";
import WhitelistMint from "./Components/WhitelistMint/WhitelistMint";
import FreeMint from "./Components/FreeMint/FreeMint";
import PublicMint from "./Components/PublicMint/PublicMint";

function App() {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        {/* <Route path="/freeminttest">
          <FreeMint />
        </Route> */}
        {/* <Route path="/whitelisttest">
          <WhitelistMint />
        </Route> */}
        {/* <Route path="/publictest">
          <PublicMint />
        </Route> */}
        {/* <Route path="/free-mint">
          <FreeMint />
        </Route> */}
        <Route path="/holders-mint">
          <WhitelistMint />
        </Route>
        <Route path="/mint">
          <PublicMint />
        </Route>
        {/* <Route path="/mydollars">
          <MyDollars />
        </Route> */}
        <Route path="/laundromat">
          <Laundromat />
        </Route>
      </Switch>
    </>
  );
}

export default App;
