import styled from "styled-components";

import { Spacer } from '../../Home/Home'
import aaron from "../../images/Aaron.png";
import dama from "../../images/Dama.png";
import dp from "../../images/DP.png";
import john from "../../images/John.png";
import twitter from "../../images/twitter.png";
import telegram from "../../images/telegram.png";
import discord from "../../images/discord.png";
import instagram from "../../images/instagram.png";

const Wrapper = styled.div`
  background-color: var(--lightGray);
  padding: 8rem 0;
  text-align: center;

  @media all and (max-width: 1023px) {
    margin-bottom: -10px;
    margin-top: -10px;
    padding: 5rem 0;
  }
`

const Image = styled.img`
  border: 4px solid #000;
`

const H5 = styled.h5`
  font-size: 2.2rem;
  line-height: 1.3;
  margin-top: 0.5rem;
`

const Icon = styled.img`
  display: inline-block;
  height: 25px;
  margin: 0 8px;
  vertical-align: middle;
  width: auto;
  :hover {
    underline: none;
  }
`

const TeamMemberContainer = styled.div`
  p {
    font-size: 1.4rem;
  }
  @media all and (max-width: 767px) {
    margin-bottom: 5rem;
  }
`

const Team = () => {
  return (
    <Wrapper id="team">
      <div className="globalWrapper">
        <h2>Meet the Team</h2>
        <p>With years of experience in the technology/social media industry, the GingerBreadBSC team wants to deliver a project which will stand the test of time in the NFT space.</p>
        <p>Below is the core team of GingerBreadBSC.</p><br />
        <div className="grid grid-center">
          <TeamMemberContainer className="col-2_sm-10">
            <Image src={dama} alt="" />
            <H5>Dama</H5>
            <p>Founder</p>
            <div className="social-container">
              <a href="https://twitter.com/DamaBSC" target="_blank" rel="noreferrer noopener"><Icon src={twitter} alt="#" /></a>
              <a href="https://t.me/gingerbreadboysbsc" target="_blank" rel="noreferrer noopener"><Icon src={telegram} alt="#" /></a>
              <a href="https://discord.gg/YYX7h8qeQ3" target="_blank" rel="noreferrer noopener"><Icon src={discord} alt="#" /></a>
            </div>
          </TeamMemberContainer>
          <TeamMemberContainer className="col-2_sm-10">
            <Image src={aaron} alt="" />
            <H5>Aaron</H5>
            <p>Social Media Manager</p>
            <div className="social-container">
              <a href="https://twitter.com/ItsAaronBSC" target="_blank" rel="noreferrer noopener"><Icon src={twitter} alt="#" /></a>
              <a href="https://t.me/gingerbreadboysbsc" target="_blank" rel="noreferrer noopener"><Icon src={telegram} alt="#" /></a>
              <a href="https://discord.gg/YYX7h8qeQ3" target="_blank" rel="noreferrer noopener"><Icon src={discord} alt="#" /></a>
            </div>
          </TeamMemberContainer>
          <TeamMemberContainer className="col-2_sm-10">
            <Image src={john} alt="" />
            <H5>John</H5>
            <p>Community Manager</p>
            <div className="social-container">
              <a href="https://twitter.com/ItsJohnBSC" target="_blank" rel="noreferrer noopener"><Icon src={twitter} alt="#" /></a>
              <a href="https://t.me/gingerbreadboysbsc" target="_blank" rel="noreferrer noopener"><Icon src={telegram} alt="#" /></a>
              <a href="https://discord.gg/YYX7h8qeQ3" target="_blank" rel="noreferrer noopener"><Icon src={discord} alt="#" /></a>
            </div>
          </TeamMemberContainer>
          <TeamMemberContainer className="col-2_sm-10">
            <Image src={dp} alt="" />
            <H5>DP</H5>
            <p>Developer</p>
            <div className="social-container">
              <a href="https://twitter.com/HipForCrypto" target="_blank" rel="noreferrer noopener"><Icon src={twitter} alt="#" /></a>
              <a href="https://t.me/gingerbreadboysbsc" target="_blank" rel="noreferrer noopener"><Icon src={telegram} alt="#" /></a>
              <a href="https://discord.gg/YYX7h8qeQ3" target="_blank" rel="noreferrer noopener"><Icon src={discord} alt="#" /></a>
            </div>
          </TeamMemberContainer>
          <TeamMemberContainer className="col-2_sm-10">
            <Image src={aaron} alt="" />
            <H5>Dom</H5>
            <p>Artist</p>
            <div className="social-container">
              <a href="https://www.instagram.com/domsketchesstuff/" target="_blank" rel="noreferrer noopener"><Icon src={instagram} alt="#" /></a>
              <a href="https://t.me/gingerbreadboysbsc" target="_blank" rel="noreferrer noopener"><Icon src={telegram} alt="#" /></a>
              <a href="https://discord.gg/YYX7h8qeQ3" target="_blank" rel="noreferrer noopener"><Icon src={discord} alt="#" /></a>
            </div>
          </TeamMemberContainer>
        </div>
      </div>
    </Wrapper>
  );
};

export default Team;
