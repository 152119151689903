import styled from "styled-components";

import gif1 from '../../images/gif1.gif'
import bg from '../../images/AboutUs.png'

const Wrapper = styled.section`
  background: url(${bg}) no-repeat center center;
  background-size: cover;
  margin-bottom: -5px;
  padding: 9rem 0;
  @media all and (max-width: 767px) {
    padding: 2rem 0;
  }
`

const Image = styled.img`
  border: 5px solid #000;

  @media all and (max-width: 767px) {
    margin-bottom: 2rem;
  }
`

const H2 = styled.h2`

`

const About = () => {
  return (
    <Wrapper id="about">
      <div className="globalWrapper">
        <div className="grid-middle">
          <div className="col-5_sm-12">
            <Image src={gif1} alt="gif of GingerBreadBoy" />
          </div>
          <div className="col-1_sm-12"></div>
          <div className="col-6_sm-12">
            <H2>About Us</H2>
            <p>GingerBreadBoys is a unique, generative set of artwork on the Binance Smart Chain. Featuring 4,012 NFTs in the collection, no two GingerBreadBoys are the same. GingerBreadBoys offers 5% BNB reflections to minters, with further utility to be announced.</p>
            <p>View our Roadmap below to learn more about the GingerBreadBoys.</p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default About;
