import styled from "styled-components";
import heroBackground from "../../images/bgs/Home3.png";
import heroBackgroundMobile from "../../images/bgs/Home3.png";
import giff from '../../images/giff.gif'

const Background = styled.div`
  background-image: url(${heroBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media all and (max-width: 767px) {
    background-image: url(${heroBackgroundMobile});
    background-size: cover;
    padding-top: initial;
  }
`;

const Wrapper = styled.div`
  // background-color: #fff;
  .grid-middle {
    margin-bottom: 35px;
  }
  @media all and (max-width: 767px) {
    .grid-middle {
      display: flex;
      justify-content: center;
      text-align: center;
      margin-bottom: 10px;
    }
  }
`;

const Content = styled.p`
  margin-bottom: 6rem;
`;

const Box = styled.div`
  background-color: white;
  padding: 30px;
  p {
    margin: 0;
  }
`;

const BoxContainer = styled.div`
  padding: 160px 25px 70px;
`;

const H2 = styled.h2`
  background: white;
  color: black;
  margin: 0 auto 40px;
  padding: 20px 0 15px;
  text-align: center;
  width: 240px;
`;

const MapItemContainer = styled.div`
  @media all and (max-width: 767px) {
    margin-bottom: 2rem;
  }
`;

const Diamond = styled.div`
  color: black;
  font-family: "JrlDirty-Regular";

  p {
    font-size: 5rem;
    z-index: 1;
  }

  @media all and (min-width: 767px) {
    height: 0;
    // width: 0;
    position: relative;
    top: -43px;
  }
`;

const VideoContainer = styled.div`
  padding: 40px 25px 70px;
  @media all and (max-width: 767px) {
    padding: 0 10px 70px 10px;
  }
`

const RoadmapImage = styled.img`
  width: 60%;
  @media all and (max-width: 767px) {
    width: 100%;
  }
`

const SpecialDiamond = styled(Diamond)`
  p {
    left: -30px;
  }
`;

// The Blueprints
// Now – 'Jack Rabbits Posse' is formed at the Rabbit Hutch (Discord with a difference)
// August '23 – The Heist is planned (pre-release)
// September '23 –  'Load the Van' The Heist commences (Release)
// October '23 – 'The Sinister' Laundromat is unveiled
// November '23 – The Treasure Hunt
// December '23 – Back on the road (The next drop)

// Upcoming
// 'Riot at The Capitol' (Art available for ordering – framed and unframed)
// 'Original Gangster' (One of one street art canvased made and shipped to gold note holders)
// 'Money Heist' (Selected holders given prizes from the prize pool)
// 'Hold on a minute lads, I've got a great idea' – Holders selected to be pivotal in the next heist.
// 'Crack the Safe' – Solve the problem, win the loot
// 'Find the Loot' – The real world/Metaverse treasure hunt begins

const RoadMap = () => {
  const roadmapArray = [
    {
      text: "Jack Rabbits Posse is formed at the Rabbit Hutch - Discord with a difference",
      percent: "Now",
    },
    {
      text: "JACKPOT and The Heist is planned - pre-release",
      percent: "Nov '23",
    },
    {
      text: "'Load the Van' The Heist commences - Release",
      percent: "'24",
    },
    {
      text: "'The Sinister' Laundromat is unveiled",
      percent: "'24",
    },
    {
      text: "The Treasure Hunt",
      percent: "'24",
    },
    {
      text: "Back on the road - The next drop",
      percent: "'24",
    },
  ];

  const roadmapArray2 = [
    {
      text: "Art available for ordering - framed and unframed",
      percent: "Riot at The Capitol",
    },
    {
      text: "One of one street art canvased made and shipped to gold note holders",
      percent: "Original Gangster",
    },
    {
      text: "Selected holders given prizes from the prize pool",
      percent: "Money Heist",
    },
    {
      text: "Holders selected to be pivotal in the next heist",
      percent: "Hold on a minute lads, I've got a great idea",
    },
    {
      text: "Solve the problem, win the loot",
      percent: "Crack the Safe",
    },
    {
      text: "The real world - Metaverse treasure hunt begins",
      percent: "Find the Loot",
    },
  ];


  return (
    <Wrapper id="roadmap">
      <Background>
        <div className="globalWrapper">
          <div className="grid" style={{ alignItems: "center" }}>
            <BoxContainer className="col-12_md-12">
              <RoadmapImage alt="roadmap" src="https://cdn.discordapp.com/attachments/1169593084275859497/1169602140994543716/JRL_BluePrint.png?ex=6555ffe0&is=65438ae0&hm=38d0f923574af0200dfe14c734c61dbe3f41ec993cc522e645bf4d92ac54dcbd&" />
              </ BoxContainer>

              {/* <BoxContainer className="col-5_md-12">
                <H2>Blueprints</H2>
                <Box>
                  <MapItemContainer className="grid grid-middle">
                    <div className="col-5_sm-12">
                      <Diamond>
                        <p style={{ marginTop: '0px' }}>{roadmapArray[0].percent}</p>
                      </Diamond>
                    </div>
                    <p className="col-7_sm-12">
                      {roadmapArray[0].text}
                    </p>
                  </MapItemContainer>
                  <MapItemContainer className="grid grid-middle">
                    <div className="col-5_sm-12">
                      <Diamond>
                        <p style={{ marginTop: '0' }}>{roadmapArray[1].percent}</p>
                      </Diamond>
                    </div>
                    <p className="col-7_sm-12">
                      {roadmapArray[1].text}
                    </p>
                  </MapItemContainer>
                  <MapItemContainer className="grid grid-middle">
                    <div className="col-5_sm-12">
                      <Diamond>
                        <p style={{ marginTop: '0' }}>{roadmapArray[2].percent}</p>
                      </Diamond>
                    </div>
                    <p className="col-7_sm-12">
                      {roadmapArray[2].text}
                    </p>
                  </MapItemContainer>
                  <MapItemContainer className="grid grid-middle">
                    <div className="col-5_sm-12">
                      <Diamond>
                        <p style={{ marginTop: '0' }}>{roadmapArray[3].percent}</p>
                      </Diamond>
                    </div>
                    <p className="col-7_sm-12">
                      {roadmapArray[3].text}
                    </p>
                  </MapItemContainer>
                  <MapItemContainer className="grid grid-middle">
                    <div className="col-5_sm-12">
                      <Diamond>
                        <p style={{ marginTop: '0' }}>{roadmapArray[4].percent}</p>
                      </Diamond>
                    </div>
                    <p className="col-7_sm-12">
                      {roadmapArray[4].text}
                    </p>
                  </MapItemContainer>
                  <MapItemContainer className="grid grid-middle">
                    <div className="col-5_sm-12">
                      <Diamond>
                        <p style={{ marginTop: '0' }}>{roadmapArray[5].percent}</p>
                      </Diamond>
                    </div>
                    <p className="col-7_sm-12">
                      {roadmapArray[5].text}
                    </p>
                  </MapItemContainer>
                </Box>
              </BoxContainer>
              <div className="col-2_sm-12"></div>
              <BoxContainer className="col-5_md-12">
                <H2>Upcoming</H2>
                <Box>
                  <MapItemContainer className="grid grid-middle">
                    <div className="col-5_sm-12">
                      <Diamond>
                        <p style={{ fontSize: '2.5rem', marginTop: '0px' }}>{roadmapArray2[0].percent}</p>
                      </Diamond>
                    </div>
                    <p className="col-7_sm-12">
                      {roadmapArray2[0].text}
                    </p>
                  </MapItemContainer>
                  <MapItemContainer className="grid grid-middle">
                    <div className="col-5_sm-12">
                      <Diamond>
                        <p style={{ fontSize: '2.4rem', marginTop: '40px' }}>{roadmapArray2[1].percent}</p>
                      </Diamond>
                    </div>
                    <p className="col-7_sm-12">
                      {roadmapArray2[1].text}
                    </p>
                  </MapItemContainer>
                  <MapItemContainer className="grid grid-middle">
                    <div className="col-5_sm-12">
                      <Diamond>
                        <p style={{ fontSize: '2.5rem', marginTop: '20px' }}>{roadmapArray2[2].percent}</p>
                      </Diamond>
                    </div>
                    <p className="col-7_sm-12">
                      {roadmapArray2[2].text}
                    </p>
                  </MapItemContainer>
                  <MapItemContainer className="grid grid-middle">
                    <div className="col-5_sm-12">
                      <Diamond>
                        <p style={{ fontSize: '2.5rem', lineHeight: '0.9', marginTop: '5px' }}>{roadmapArray2[3].percent}</p>
                      </Diamond>
                    </div>
                    <p className="col-7_sm-12">
                      {roadmapArray2[3].text}
                    </p>
                  </MapItemContainer>
                  <MapItemContainer className="grid grid-middle">
                    <div className="col-5_sm-12">
                      <Diamond>
                        <p style={{ fontSize: '2.5rem', marginTop: '40px' }}>{roadmapArray2[4].percent}</p>
                      </Diamond>
                    </div>
                    <p className="col-7_sm-12">
                      {roadmapArray2[4].text}
                    </p>
                  </MapItemContainer>
                  <MapItemContainer className="grid grid-middle">
                    <div className="col-5_sm-12">
                      <Diamond>
                        <p style={{ fontSize: '2.5rem', marginTop: '40px' }}>{roadmapArray2[5].percent}</p>
                      </Diamond>
                    </div>
                    <p className="col-7_sm-12">
                      {roadmapArray2[5].text}
                    </p>
                  </MapItemContainer>
                </Box>
              </BoxContainer> */}

              <VideoContainer className="col-12_md-12">
                <img src={giff} alt="bucks" style={{ margin: '0 auto', width: '100%' }} />
              </VideoContainer>
          </div>
        </div>
      </Background>
    </Wrapper>
  );
};

export default RoadMap;
