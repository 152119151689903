import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import Sidebar from "./Sidebar";
import WalletConnect from "../WalletConnect/WalletConnect";
import twitter from "../../images/white4.png";
import telegram from "../../images/white3.png";
import discord from "../../images/white2.png";
import os from "../../images/os.png";

import logo from "../../images/bgs/LogoWeb.png";
import whitepaper from '../../images/Jack_Rabbits_Loot_Documentation_March-24.pdf'

const Container = styled.div`
  position: fixed;
  width: 100%;
  z-index: 1;
`;

const Wrapper = styled.div`
  font-size: 2.4rem;
  padding: 1rem 25px 0;
  background: black;
  width: 100%;
  z-index: 9999;
  // height: 100vh;

  .text-right {
    display: flex; // pre launch only
    justify-content: space-between; // pre launch only
  }

  .hamburger {
    display: none;
    cursor: pointer;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  .wallet_button {
    font-family: "AmericanPurpose";
    color: #000;
    background-color: #fff;
    padding: 1.5rem 1rem 1rem;
    font-size: 1.8rem;
    margin-left: 2rem;
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.2s all;

    &:hover {
      color: #000;
      background-color: #fff;
      border: 1px solid #fff;
    }
    @media only screen and (min-width: 767px) {
      // margin-bottom: 10px;
      margin-top: 10px;
    }
  }

  @media only screen and (max-width: 767px) {
    a {
      color: #fff;
    }

    .hamburger {
      display: block;
      display: inline;
      vertical-align: middle;
    }
  }
  @media only screen and (min-width: 1920px) {
    padding: 1rem 7%;
  }
`;

const Image = styled.img`
  max-width: 160px;
  margin: 0 0 0 3%;
  float: left;

  @media only screen and (max-width: 767px) {
    width: 100px;
  }
`;

const Icon = styled.img`
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
  width: auto;
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;

  .social-container a {
    &:hover {
      border-bottom: none;
    }
  }

  a {
    margin-left: 2rem;

    &:hover {
      border-bottom: 2px solid #3c3c3c;
    }
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const Social = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;

  a:hover {
    border: none;
  }
`;

const MenuLeft = styled.div`
  margin-bottom: -15px;
`;

const MenuRight = styled.div`
  margin-right: 25px;
`;


class Navbar extends React.Component {
  constructor() {
    super();

    this.state = {
      sidebar: false,
    };
  }

  onCheck = () => {
    if (!this.state.sidebar) {
      document.body.classList.add("menu-open");
      this.setState({ sidebar: true });
    } else if (this.state.sidebar) {
      document.body.classList.remove("menu-open");
      this.setState({ sidebar: false });
    }
  };

  componentWillUnmount() {
    document.body.classList.remove("menu-open");
    this.setState({ sidebar: false });
  }

  render() {
    const menuItems = [
      {
        text: "Home",
        url: "/",
      },
      {
        text: "About",
        url: "/#about",
      },
      {
        text: "Roadmap",
        url: "/#roadmap",
      },
      // {
      //   text: "Trading Post",
      //   url: "https://trade.jackrabbitsloot.com",
      // },
      // {
      //   text: "My dollars",
      //   url: "/mydollars",
      // },
      // {
      //   text: "Members merch",
      //   url: "/",
      // },
      {
        text: "The laundromat",
        url: "/laundromat",
      },
    ];

    return (
      <Container>
        <Wrapper className="navWrapper">
          <div className="grid-middle">
            <div className="col-12 text-right">
              <a href="/">
                <Image src={logo} />
              </a>
              <MenuContainer className="mobile-white">
                <MenuLeft>
                <a style={{color: "#ffb4b4"}}
                href="https://www.jackrabbitsloot.com/mint">public mint</a>
                <a style={{color: "#ffb4b4"}} href="https://www.jackrabbitsloot.com/holders-mint">holders mint</a>
                {/* <a href="https://trade.jackrabbitsloot.com" target="_blank">trading post</a> */}
                  {menuItems.map((menuItem) => {
                    return <a href={menuItem.url}>{menuItem.text}</a>;
                  })}
                  {/* <a href="https://trade.jackrabbitsloot.com" target="_blank">trading post</a> */}
                  <a href={whitepaper} target="_blank">blueprint</a>
                </MenuLeft>
                <MenuRight>
                  <Social>
                    <a
                      href="https://twitter.com/JackRabbitsLoot"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Icon src={twitter} alt="#" />
                    </a>
                    <a
                      href="https://discord.gg/qsAcXMdyMP"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Icon src={discord} alt="#" />
                    </a>
                    <a
                      href="https://opensea.io/collection/jack-rabbits-loot"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Icon src={os} alt="#" />
                    </a>
                  </Social>
                  {/* <WalletConnect /> */}
                </MenuRight>
              </MenuContainer>
              <GiHamburgerMenu
                className="hamburger"
                size="30"
                color="#fff"
                onClick={this.onCheck}
              />
            </div>
            {this.state.sidebar && (
              <Sidebar menuItem={menuItems} showSidebar={this.onCheck} />
            )}
          </div>
        </Wrapper>
      </Container>
    );
  }
}

export default Navbar;
