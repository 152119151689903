import styled from "styled-components";

import heroBackground from '../../images/bgs/Home2.png'
import heroBackgroundMobile from '../../images/bgs/Home2.png'
import gifff from '../../images/JRLpfp.gif'
import gifff2 from '../../images/IMG_9242.gif'
import gifff3 from '../../images/IMG_9196.gif'


// 0x1111111111111111111111111111111111111111,5000000000000000000
// 0x2222222222222222222222222222222222222222,2500000000000000000
// 0xEC75dF0f8422E561EA0E6524DD0251695D7b64C8,5000000000000000000
// 0x77ab2da234C14fa289894f49714630DF0266d329,2000000000000000000
// import { MerkleTree } from "merkletreejs";
// const ethers = require("ethers")
// import keccak256 from "keccak256";
// // inputs: array of users' addresses and quantity
// // each item in the inputs array is a block of data
// // Alice, Bob and Carol's data respectively
// const inputs = [
//   {
//     address: "0xEC75dF0f8422E561EA0E6524DD0251695D7b64C8",
//     quantity: 5,
//   },
//   {
//     address: "0x77ab2da234C14fa289894f49714630DF0266d329",
//     quantity: 2,
//   },
// ];
// // create leaves from users' address and quantity
// const leaves = inputs.map((x) =>
//   ethers.utils.solidityKeccak256(
//     ["address", "uint256"],
//     [x.address, x.quantity]
//   )
// );
// // create a Merkle tree
// const tree = new MerkleTree(leaves, keccak256, { sort: true });
// console.log('merkletree', tree.toString());

const Wrapper = styled.section`
`

const Background = styled.div`
  background-image: url(${heroBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media all and (max-width: 767px) {
    background-image: url(${heroBackgroundMobile});
    background-size: cover;
    padding-top: initial;
    height: auto;
  }
`

const Box = styled.div`
  background-color: white;
  padding: 30px;
  p {
    margin: 0;
  }
  a {
    color: black;
  }
`

const BoxContainer = styled.div`
  padding: 160px 25px 70px;
`

const H2 = styled.h2`
  background: white;
  color: black;
  margin: 0 auto 40px;
  padding: 20px 0 15px;
  text-align: center;
  width: 240px;
`

const VideoContainer1 = styled.div`
padding: 130px 25px 70px;
@media all and (max-width: 767px) {
    padding: 0;
  }
`

const VideoContainer2 = styled.div`
padding: 230px 25px 0;
@media all and (max-width: 767px) {
    padding: 0;
  }
`
const AboutSection = () => {
  return (
    <Wrapper id="about">
      <Background>
        <div className="globalWrapper">
          <div className="grid">

            {/* <div className="col-4_md-12" /> */}
            <BoxContainer className="col-7_md-12">
              {/* <H2>About us</H2> */}


              <Box>
                <p>Embark on an extraordinary adventure with Jack Rabbit, the mythical bank robber who converts stolen cash into mesmerizing NFTs. But that's not all! As you delve into the world of his daring exploits and digital artistry, there's an added twist. Solve the puzzle, crack the code, and you may be rewarded with real-world art inspired by Jack Rabbit's NFTs.
                  <br /><br />
                  Imagine owning a tangible piece of art that mirrors the digital masterpiece, bridging the gap between the virtual and physical realms. By immersing yourself in this captivating journey, you have the opportunity to unlock not only the thrill of the chase but also a coveted artistic treasure. Prepare to be enthralled as the line blurs between the digital and the real, offering an experience unlike any other.
                  <br /><br />
                  Unleash your inner sleuth, explore the world of Jack Rabbit's NFTs, and discover the possibility of obtaining a tangible artistic gem that reflects the spirit of his audacious escapades. Brace yourself for a thrilling quest that combines the allure of digital art with the tangible beauty of the physical world.

                </p>
              </Box>
            </BoxContainer>
            <VideoContainer2 className="col-5_md-12">
              <Box>
                <img src={gifff2} alt="bucks" />
              </Box>
            </VideoContainer2>

            {/* <div className="col-4_md-12" /> */}
            <VideoContainer1 className="col-5_md-12">
              <Box>
                <img src={gifff3} alt="bucks" />
              </Box>
            </VideoContainer1>
            <BoxContainer className="col-7_md-12">
              {/* <H2>About us</H2> */}
              <Box>
                <p><a href="https://twitter.com/THE_SAINT_ART" target="_blank" rel="noreferrer noopener">@THE_SAINT_ART</a> and <a href="https://twitter.com/JaxJRL" target="_blank" rel="noreferrer noopener">Jax</a> are NFT enthusiasts with a joint vision to ensure longevity in the NFT world. <br /><br />

                  Partnering with the experienced <a href="https://twitter.com/hip_labs" target="_blank" rel="noreferrer noopener">@hip_labs</a> team, this project is designed to bring like-minded individuals on a journey that is both fun and rewarding. <br /><br />

                  We want to make a better life not just for us and our families but for countless people and families across the world. You can find information about us on <a
                    href="https://twitter.com/JackRabbitsLoot"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Twitter
                  </a>.
                  {/* or you can contact us in our Discord where we'll be happy to answer most questions. */}
                </p>
              </Box>


            </BoxContainer>

          </div>

        </div>
      </Background>
    </Wrapper>
  );
};

export default AboutSection;
