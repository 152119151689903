import styled from "styled-components";
import heroBackground from "../../images/guntweet.png";
import heroBackgroundMobile from "../../images/bgs/Home3.png";
import giff from '../../images/giff.gif'

const Background = styled.div`
  // background-image: url(${heroBackgroundMobile});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media all and (max-width: 767px) {
    // background-image: url(${heroBackgroundMobile});
    background-size: cover;
    padding-top: initial;
  }
`;

const Wrapper = styled.div`
  // background-color: #fff;
  .grid-middle {
    margin-bottom: 35px;
  }
  @media all and (max-width: 767px) {
    .grid-middle {
      display: flex;
      justify-content: center;
      text-align: center;
      margin-bottom: 10px;
    }
  }
`;

const Content = styled.p`
  margin-bottom: 6rem;
`;

const Box = styled.div`
  background-color: white;
  padding: 30px;
  p {
    margin: 0;
  }
`;

const BoxContainer = styled.div`
  padding: 160px 25px 70px;
`;

const H2 = styled.h2`
  background: white;
  color: black;
  margin: 0 auto 40px;
  padding: 20px 0 15px;
  text-align: center;
  width: 240px;
`;

const MapItemContainer = styled.div`
  @media all and (max-width: 767px) {
    margin-bottom: 2rem;
  }
`;

const Diamond = styled.div`
  color: black;
  font-family: "JrlDirty-Regular";

  p {
    font-size: 4rem;
    z-index: 1;
  }

  @media all and (min-width: 767px) {
    height: 0;
    width: 0;
    position: relative;
    top: -43px;
  }
`;

const VideoContainer = styled.div`
  padding: 180px 25px 70px;
  @media all and (max-width: 767px) {
    padding: 0;
  }
`
const SpecialDiamond = styled(Diamond)`
  p {
    left: -30px;
  }
`;

const Bottom = () => {
  const roadmapArray = [
    {
      text: "Community wallet (Bank) gets funded with 10 ETH.",
      percent: "1,000",
    },
    {
      text: "Another 10 ETH added to the Bank. Prize draw made amongst holders for 3 ETH prize.",
      percent: "5,000",
    },
    {
      text: "Community Vault setup. Acquisition of high value NFTs up to a total value of. First round of printed artwork distributed to qualifying holders.",
      percent: "7,500",
    },
    {
      text: "Merch launched with discounts for original posse. First charitable donation made. Another 10 ETH added to the Bank. Prize draw made amongst holders for 3 ETH prize.",
      percent: "7,777",
    },
  ];

  return (
    <Wrapper id="bottom">
      <Background>
        <div className="globalWrapper">
          <div className="grid" style={{ alignItems: "center" }}>
            <VideoContainer className="col-12">
              <Box>
                <img src={heroBackground} alt="bucks" />
              </Box>
            </VideoContainer>
          </div>
        </div>
      </Background>
    </Wrapper>
  );
};

export default Bottom;
