import styled from "styled-components";

// import heroBackground from '../../images/bgs/Home1.png'
// import heroBackgroundMobile from '../../images/bgs/Home1.png'
import video from '../../images/Teaser.mp4'

import heroBackground from "../../images/bgs/MintDapp.png";
import heroBackgroundMobile from "../../images/bgs/MintDapp.png";

const Wrapper = styled.section`
`

const Background = styled.div`
  background-image: url(${heroBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media all and (max-width: 767px) {
    background-image: url(${heroBackgroundMobile});
    background-size: cover;
    padding-top: initial;
    height: auto;
  }
`

const Box = styled.div`
  background-color: white;
  padding: 30px;
  p {
    margin: 0;
  }
`

const BoxContainer = styled.div`
  padding: 180px 25px 70px;
  // .col-7_md-12 {
  //   padding-top: 0;
  // }
  // @media all and (max-width: 767px) {
  //   padding: 0;
  // }
`

const VideoContainer = styled.div`
  padding: 180px 25px 70px;
  @media all and (max-width: 767px) {
    padding: 0;
  }
`

const LinkOut = styled.a`
  color: #fff;
  background-color: #000;
  padding: 1.5rem;
  margin: 0 15px 15px 0;
  font-size: 2.5rem;
  font-family: "AmericanPurpose";
  border: 1px solid transparent;
  cursor: pointer;
  transition: 0.2s all;
  display: inline-flex;
  text-decoration: none;
  &:hover {
    color: #fff;
    background-color: #2c2c2c;
    // border: 1px solid #000;
  }
`;

const H2 = styled.h2`
  background: white;
  color: black;
  margin: 0 auto 40px;
  padding: 20px 0 15px;
  text-align: center;
  width: 240px;
`

const HeroSection = () => {
  return (
    <Wrapper id="hero">
      <Background>
        <div className="globalWrapper">
          <div className="grid" style={{ alignItems: "center" }}>
            <BoxContainer className="col-5_md-12">
              <H2>Welcome</H2>

              <Box>
                <br />
                <h1>MINTING NOW</h1><br />
                <LinkOut
                  className="button"
                  href="/mint"
                  // style={{ pointerEvents: "none", color: "darkgray" }}
                >PUBLIC</LinkOut>
                {/* <LinkOut
                  className="button"
                  href="/whitelist-mint"
                >WHITELIST</LinkOut> */}
                {/* <LinkOut
                  className="button"
                  href="/free-mint"
                >FREE</LinkOut><br /><br /> */}
                <p>This is the Hideout of the master thief known only as <br /> "Jack Rabbit".<br /><br />
                  A modern-day Robin Hood, his mission is to take real world money from the rich and redistribute it to the people as NFTs - designed to increase in value and bring wider prosperity to artists and collectors alike.<br /><br />
                  The first 7,777 NFTs will be launched soon. More drops will follow as Jack Rabbit loots the globe. As he does so, holders will be invited to join in and help spread the wealth, increasing their chances of finding the ultimate prize! With a new artist chosen for each drop and sizeable charitable donations, you won't want to miss out.<br /><br />
                  Here you will find everything you need to know about Jack Rabbit's plan, the tools to mint your own loot and details about how you can help Jack Rabbit promote and secure the future of NFT generations to come.
                </p>
              </Box>
            </BoxContainer>

            <VideoContainer className="col-7_md-12">
              <Box>

                <video
                  width="100%"
                  controls>
                  <source src={video} type="video/mp4" />
                </video>
              </Box>
            </VideoContainer>
          </div>
        </div>
      </Background>
    </Wrapper>
  );
};

export default HeroSection;
