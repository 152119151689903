import styled from "styled-components";
import { IoMdClose } from "react-icons/io";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import WalletButton from "../WalletConnect/WalletConnect"
import twitter from "../../images/white4.png";
import telegram from "../../images/white3.png";
import discord from "../../images/white2.png";
import os from "../../images/os.png";
import whitepaper from '../../images/Jack_Rabbits_Loot_Documentation_March-24.pdf'

const Wrapper = styled.div`
`;

const SidebarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  background: white;
`

const SidebarEl = styled.div`
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100vh;
  z-index: 9999;
  
  a {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    margin: 8px 0;
    padding: 10px 20px;
  }

  @media all and (max-width: 768px) {
    .wallet_button {
      margin-left: 0 !important;
      margin-top: 10px;
    }
  }
`

const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 1.5rem;
  margin-top: 5rem;
  display: inline-block;
  vertical-align: middle;
  width: auto;
`

const CloseBtn = styled.div`
  background-color: #000;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 30px 1rem 0;
  cursor: pointer;
  z-index: 9999;
`

const Sidebar = (props) => {
  const { menuItem, showSidebar } = props;

  return (
    <Wrapper>
      <SidebarContainer>
        <CloseBtn>
          <IoMdClose color="#fff" size={30} onClick={showSidebar} />
        </CloseBtn>
        <SidebarEl>
          <Link to="/" onClick={showSidebar}>
            <NavLink to="/">Homepage</NavLink>
          </Link>
          <a style={{color: "#ffb4b4"}} href="https://www.jackrabbitsloot.com/mint">public mint</a>
          <a style={{color: "#ffb4b4"}} href="https://www.jackrabbitsloot.com/holders-mint">holders mint</a>
          {/* <Link to="https://trade.jackrabbitsloot.com" onClick={showSidebar}>
            <NavLink to="https://trade.jackrabbitsloot.com" target="_blank">trading post</NavLink>
          </Link> */}
          {/* <Link to="/" onClick={showSidebar}>
            <NavLink to="/mydollars">My dollars</NavLink>
          </Link> */}
          <Link to="/" onClick={showSidebar}>
            <NavLink to="/laundromat">the Laundromat</NavLink>
          </Link>
          {/* <a href="https://trade.jackrabbitsloot.com" target="_blank" rel="noreferrer noopener">trading post</a> */}
          <a href={whitepaper} target="_blank" rel="noreferrer noopener">blueprint</a>
          {/* <WalletButton /> */}
          <div className="social-container">
            <a href="https://twitter.com/JackRabbitsLoot" target="_blank" rel="noreferrer noopener"><Icon src={twitter} alt="#" /></a>
            <a href="https://discord.gg/qsAcXMdyMP" target="_blank" rel="noreferrer noopener"><Icon src={discord} alt="#" /></a>
            <a href="https://opensea.io/collection/jack-rabbits-loot" target="_blank" rel="noreferrer noopener"><Icon src={os} alt="#" /></a>
          </div>
        </SidebarEl>
      </SidebarContainer>
    </Wrapper>
  );
};

export default Sidebar;
