import { useEffect, useState } from "react";
import styled from "styled-components";
import { ourABI } from "../../ABI/gingerBreadBoys";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import WalletConnect from "../WalletConnect/WalletConnect";
import heroBackground from "../../images/bgs/MyDollars.png";
import heroBackgroundMobile from "../../images/bgs/MyDollars.png";

const Wrapper = styled.div`
  background-color: #fff;
  text-align: center;
  padding-top: 120px;
  p {
    margin: 0;
  }

  .nft-image {
    // border: 4px solid black;
  }


  .wallet_button {
    font-family: "AmericanPurpose";
    color: #000;
    background-color: #fff;
    padding: 1.5rem 1rem 1rem;
    font-size: 1.8rem;
    margin-left: 2rem;
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.2s all;

    &:hover {
      color: #000;
      background-color: #fff;
      border: 1px solid #fff;
    }
    @media only screen and (min-width: 767px) {
      // margin-bottom: 10px;
      margin-top: 10px;
    }
  }
  @media only screen and (max-width: 767px) {
    padding-top: 50px;
  }
`;

const Background = styled.div`
  background: url(${heroBackground}) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  @media all and (max-width: 767px) {
    background-image: url(${heroBackgroundMobile});
    background-size: cover;
    padding-top: initial;
  }
`;

const BackgroundBefore = styled.div`
  background: url(${heroBackground}) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  // height: calc(100vh - 120px);

  @media all and (max-width: 767px) {
    background-image: url(${heroBackgroundMobile});
    background-size: cover;
    padding-top: initial;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Caps = styled.span`
  text-transform: capitalize;
`;

const H1 = styled.h1`
  color: #FFF;
  margin: 0 auto;
  padding: 30px 0 15px;
  text-align: center;
`;

const H3 = styled.h3`
  background: white;
  color: black;
  margin: 0 auto 40px;
  padding: 20px ​0 15px;
  text-align: center;
  width: 330px;
  margin-bottom: 4rem;
`;

const BoyWrapper = styled.div`
  // border: 1px solid #3c3c3c;
  // border-radius: 5px;
  // padding: 1rem;
  // background-color: #d9d9db;
  margin: 0 auto 50px;
`;

const Loader = styled.div`
  border-radius: 50%;
  width: 10em;
  height: 10em;
  margin: 150px auto 60px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #3c3c3c;
  border-right: 1.1em solid #3c3c3c;
  border-bottom: 1.1em solid #3c3c3c;
  border-left: 1.1em solid black;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  overflow: hidden;

  &::after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const Space = styled.div`
  height: 5rem;
`;

const Error = styled.div`
  background: white;
  color: black;
  padding: 40px 0;
  margin: 0 auto;
  text-align: center;

  @media all and (max-width: 767px) {
    padding: 50px 0 10px;
  }
`;



const MyDollars = () => {
  const { account, library } = useWeb3React();
  const [gingerBreadBoysPurchased, setGingerBreadBoysPurchased] = useState([]);
  const [mintedMessage, setMintedMessage] = useState(
    "Connect to your wallet to see your collection of sloths."
  );
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [loadedGingerBreadBoys, setLoadedGingerBreadBoys] = useState(0);
  const [totalGingerBreadBoys, setTotalGingerBreadBoys] = useState(0);

  const collectionNFT = async () => {
    const nb = [];
    if (account) {
      let num = null;
      const web3 = new Web3(library.provider);
      const gingerBreadBoyscontract = new web3.eth.Contract(
        ourABI,
        "0x7eAa424bbff6191bAaCDA085E2ed742Cd3d9c669"
        );
        try {
          num = await gingerBreadBoyscontract.methods
          .walletOfOwner(account)
          .call();
          
        } catch (e) {
          console.log(await gingerBreadBoyscontract.methods
            .walletOfOwner(account)
            .call()
            ,'gingerBreadBoyscontract')
          setIsLoading(false);
          setError(`Please connect to the correct wallet.`);
        }
        if (typeof num !== Number && !num) {
          console.log(num,'num')
        setError(
          "Something went wrong. Please try again, and ensure you are connected to the right network. 1"
        );
        setIsLoading(false);
        return;
      } else if (num && num.length === 0) {
        setMintedMessage("Go purchase a Dollar to view it here!");
      } else if (num && num.length === 1) {
        setMintedMessage("You have looted 1 Dollar");
      } else {
        setMintedMessage(`You have looted ${num.length} Dollars`);
      }
      setTotalGingerBreadBoys(num.length);
      for (let i = 0; i < num.length; i++) {
        await fetch(`https://api.gingerbreadbsc.com/api/${num[i]}`) // TODO
          .then((response) => {
            if (response.ok) return response.json();
            throw new Error("Network response was not ok.");
          })
          .then(function (data) {
            const gingerBreadBoys = {
              tokenId: data.tokenId,
              name: data.name,
              image: data.image,
            };
            data.attributes.forEach((attribute) => {
              gingerBreadBoys[attribute.trait_type] = attribute.value;
            });
            setLoadedGingerBreadBoys(i + 1);
            nb.push(gingerBreadBoys);
          })
          .catch((error) => {
            setError(
              "Something went wrong. Please try again, and ensure you are connected to the right network."
            );
          })
          .finally(() => {});
      }
      setIsLoading(false);
    }

    nb.sort(function (a, b) {
      return a.tokenId - b.tokenId;
    });
    setGingerBreadBoysPurchased(nb);
    return nb;
  };

  useEffect(async () => {
    await collectionNFT();
  }, [account]);

  if (!account) {
    return (
      <Wrapper>
        <BackgroundBefore>
          <div className="grid">
            <div className="col-12">
              <Error>
                It seems you're not connected to the correct blockchain. Please
                connect to the correct wallet. 2
              </Error>
              <Space />
              <div>
                <WalletConnect />
              </div>
              <Space />
            </div>
          </div>
        </BackgroundBefore>
      </Wrapper>
    );
  }

  // if (error) {
  //   console.log('error',error)
  //   return (
  //     <Wrapper>
  //       <BackgroundBefore>
  //         <div className="grid">
  //           <div className="col-12">
  //             <Space />
  //             <p>{error} 3</p>
  //             <Space />
  //           </div>
  //         </div>
  //       </BackgroundBefore>
  //     </Wrapper>
  //   );
  // }

  if (isLoading) {
    return (
      <>
        <div className="grid bg-white">
          <div className="col-12">
            <Loader>Loading...</Loader>
            <H1 className="text-center">
              {loadedGingerBreadBoys} of {totalGingerBreadBoys} Dollars Loaded.
            </H1>
          </div>
        </div>
      </>
    );
  }

  return (
    <Wrapper id="mydollars">
      <Background>
        <div className="globalWrapper">
          <Space />
          <H1>My Dollars</H1>
          <H3>{mintedMessage}</H3>
          <div className="grid-equalHeight">
            {gingerBreadBoysPurchased.map((gingerBreadBoys) => {
              return (
                <div
                  className="col-4_lg-4_md-6_sm-12"
                  key={gingerBreadBoys.tokenId}
                >
                  <BoyWrapper>
                    <img
                      className="nft-image"
                      id={gingerBreadBoys.name}
                      src={gingerBreadBoys.image}
                      alt=""
                    />
                    {/* {Object.keys(gingerBreadBoys).map(key => {
                    if (gingerBreadBoys[key] === gingerBreadBoys.image || gingerBreadBoys[key] === gingerBreadBoys.tokenId) return <></>
                    if (gingerBreadBoys[key] === gingerBreadBoys.name) {
                      return <Bold><Caps>GingerBreadBoy</Caps> {gingerBreadBoys[key]}</Bold>
                    }
                    return (
                      <p><Bold><Caps>{key}</Caps>:</Bold> {gingerBreadBoys[key]}</p>
                    )
                  })} */}
                  </BoyWrapper>
                </div>
              );
            })}
          </div>
        </div>
      </Background>
    </Wrapper>
  );
};

export default MyDollars;
