import styled from "styled-components";
import gif2 from '../../images/gif2.gif'

import committed1 from '../../images/committed1.png'
import committed2 from '../../images/committed2.png'

const Wrapper = styled.section`
  background-color: #fbfb9d;
  padding: 8rem 0;

  @media all and (max-width: 1023px) {
    margin-bottom: -10px;
    margin-top: -10px;
    padding: 5rem 0;
  }
`

const ImageContainer = styled.div`
  flex-basis: 24%;
`

const Image = styled.img`
  border: 5px solid #000;

`

const H2 = styled.h2`
  line-height: 5rem;
`

const Content = styled.div`
  text-align: center;
  font-size: 2rem;
  flex-basis: 52%;
  line-height: 1.8;

  @media all and (max-width: 767px) {
    flex-basis: 100%;
  }
`

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: end;

  @media all and (max-width: 767px) {
    justify-content: center;
  }
`

const Info = () => {
  return (
    <Wrapper id="info">
      <div className="globalWrapper">
        <div className="grid-middle">
          <div className="col-6_sm-12">
            <H2>We're Committed to Making a Difference</H2>
            <p>For every 10 GinerBreadBoys baked, we will be donating one physical GingerBread Man to local food shelters, charities, or children's hospitals.</p>
            <p>The location of where these batches of GingerBread Men go, will be voted on by the community.</p>
            <p>In these times of uncertainty, the GingerBreadBSC Team is wanting to help out and make a difference.</p>
          </div>
          <div className="col-1_sm-12"></div>
          <div className="col-5_sm-12">
            <Image src={gif2} alt="gif of GingerBreadBoy" />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Info;
